.container-section {
	padding: 20px;
	border-radius: 10px;
	font-size: 20px;
	margin-bottom: 15px;
    cursor: pointer;
}

.container-section-unselected {
	border: 1px solid rgb(224, 224, 224);
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: rgba(0, 0, 0, 0.1) 2px 6px 10px 0px;
	color: black
}

.container-section-selected {
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: rgba(0, 0, 0, 0.1) 2px 6px 10px 0px;
    background-color: rgb(93, 95, 239);
	color: white
}